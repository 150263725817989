/* src/components/Test/Test.css */
.publication-container {
    display: flex; /* Use Flexbox */
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
    height: 100vh; /* Full viewport height */
    padding: 40px; /* Increased padding for a spacious feel */
    background-color: #121212; /* Dark background for modern look */
    color: #e0e0e0; /* Light gray text for better readability */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5); /* Subtle shadow for depth */
}

h1 {
    font-size: 2.5em; /* Larger heading */
    margin-bottom: 20px; /* Space below the heading */
    text-align: center; /* Centered heading */
    color: #ffcc00; /* Highlight color for the heading */
}

.publication-list {
    list-style-type: none; /* Remove default list styling */
    padding: 0; /* Remove default padding */
    text-align: center; /* Center text in the list */
}

.publication-list li {
    margin-bottom: 20px; /* Space between items */
    line-height: 1.8; /* Improved line spacing */
    border-bottom: 1px solid #444; /* Subtle separator line */
    padding-bottom: 10px; /* Space below each item */
}

.publication-list strong {
    color: #ffcc00; /* Highlight author names */
    font-weight: bold; /* Bold for emphasis */
}

.publication-list a {
    color: #ffcc00; /* Link color */
    text-decoration: none; /* Remove underline */
    transition: color 0.3s; /* Smooth transition for hover effect */
}

.publication-list a:hover {
    color: #ffffff; /* Change color on hover */
    text-decoration: underline; /* Underline on hover */
}

.journal-name {
    color: #66ccff; /* Different color for journal names */
    font-weight: bold; /* Bold for emphasis */
}

.publication-list p {
    margin: 5px 0; /* Space around paragraphs */
    color: #b0b0b0; /* Slightly lighter text for descriptions */
}